/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

button:active {
  transform: scale(0.95);
}

#sentry-feedback {
  --bottom: auto;
  --right: 8.5%;
  --submit-background: #0c2c74;
}

/* Snackbar */
.mat-mdc-snack-bar-container {
  --mat-mdc-snack-bar-button-color: #ffffff;
  --mdc-snackbar-supporting-text-color: #ffffff;
  --mat-snack-bar-button-color: #ffffff;

  &.app-notification-error {
    --mdc-snackbar-container-color: #f23a2f;
  }

  &.app-notification-warning {
    --mdc-snackbar-container-color: #f2d22f;
  }

  &.app-notification-info {
    --mdc-snackbar-container-color: #2f66f2;
  }

  &.app-notification-success {
    --mdc-snackbar-container-color: #43a446;
  }
}
